import { ParcelTemplate, ProviderConfig, Providers } from './types';
import { isTestEnv } from '../index';

const defaultValues: ProviderConfig = Object.freeze({
  maxWeight: 30,
  maxWidth: 100,
  maxHeight: 100,
  maxLength: 100,
  parcelTemplates: {},
  rules: {},
  extra: {},
  faq: {},
  services: {},
  info: {
    description: '',
    logo: '',
  },
});

const InPostTemplates: ParcelTemplate[] = [
  {
    label: 'Size A',
    type: 'small',
    width: 8,
    height: 38,
    length: 64,
    maxWeight: 25,
  },
  {
    label: 'Size B',
    type: 'medium',
    width: 19,
    height: 38,
    length: 64,
    maxWeight: 25,
  },
  {
    label: 'Size C',
    type: 'large',
    width: 41,
    height: 38,
    length: 64,
    maxWeight: 25,
  },
];

const InPostGBTemplates: ParcelTemplate[] = [
  {
    label: 'Size A',
    type: 'small',
    width: 8,
    height: 38,
    length: 64,
    maxWeight: 15,
  },
  {
    label: 'Size B',
    type: 'medium',
    width: 19,
    height: 38,
    length: 64,
    maxWeight: 15,
  },
  {
    label: 'Size C',
    type: 'large',
    width: 41,
    height: 38,
    length: 64,
    maxWeight: 15,
  },
];

export const providersConfig: Record<Providers, ProviderConfig> = Object.freeze({
  [Providers.InPost]: {
    ...defaultValues,
    maxWeight: 30,
    services: {
      return: {
        maxWeight: 25,
        analytics: {
          bookID: '111',
          payID: '112',
        },
      },
      courier: {
        analytics: {
          bookID: '211',
          payID: '212',
        },
      },
      postomat: {
        maxWeight: 25,
        analytics: {
          bookID: '311',
          payID: '312',
        },
      },
    },
    parcelTemplates: {
      return: InPostTemplates,
      postomat: InPostTemplates,
    },
    rules: {
      return: ['rates.rules.[provider].inpost.1', 'rates.rules.[provider].inpost.2', 'rates.rules.[provider].inpost.3'],
      courier: [
        'rates.rules.[provider].inpost.1',
        'rates.rules.[provider].inpost.2',
        'rates.rules.[provider].inpost.3',
      ],
      postomat: [
        'rates.rules.[provider].inpost.1',
        'rates.rules.[provider].inpost.2',
        'rates.rules.[provider].inpost.3',
        'rates.rules.[provider].inpost.4',
      ],
    },
    faq: {
      return: [
        'kb.accordion.noPrinter.link1',
        'kb.accordion.noPrinter.link2',
        'kb.accordion.noPrinter.link3',
        // 'kb.accordion.noPrinter.link4',
        'kb.accordion.noPrinter.link5',
        'kb.accordion.noPrinter.link6',
        'kb.accordion.noPrinter.link7',
        'kb.accordion.noPrinter.link8',
        'kb.accordion.noPrinter.link9',
      ],
      courier: [
        'kb.accordion.courier.link1',
        'kb.accordion.courier.link2',
        'kb.accordion.courier.link3',
        'kb.accordion.noPrinter.link3',
        'kb.accordion.noPrinter.link5',
        'kb.accordion.noPrinter.link6',
        'kb.accordion.noPrinter.link7',
        'kb.accordion.noPrinter.link8',
      ],
      postomat: [
        'kb.accordion.courier.link2',
        'kb.accordion.courier.link3',
        'kb.accordion.courier.link3',
        'kb.accordion.noPrinter.link3',
        // 'kb.accordion.noPrinter.link4',
        'kb.accordion.noPrinter.link5',
        'kb.accordion.noPrinter.link6',
        'kb.accordion.noPrinter.link7',
        'kb.accordion.noPrinter.link8',
      ],
    },
    extra: {
      // TODO: make dropOffPoint by services
      dropOffPoint: isTestEnv ? 'KRA120' : 'KRA23A',
    },
  },
  [Providers.InPostGB]: {
    ...defaultValues,
    maxWeight: 15,
    services: {
      in_post_uk_l2a: {
        customSize: false,
        analytics: {
          bookID: '151',
          payID: '152',
        },
      },
      in_post_uk_instant: {
        analytics: {
          bookID: '351',
          payID: '352',
        },
      },
    },
    parcelTemplates: {
      in_post_uk_l2a: InPostGBTemplates,
      in_post_uk_instant: InPostGBTemplates,
    },
    rules: {
      in_post_uk_l2a: [
        'rates.rules.[provider].inpost_gb.1',
        'rates.rules.[provider].inpost_gb.2',
        'rates.rules.[provider].inpost_gb.3',
      ],
      in_post_uk_instant: [
        'rates.rules.[provider].inpost_gb.1',
        'rates.rules.[provider].inpost_gb.2',
        'rates.rules.[provider].inpost_gb.3',
      ],
    },
    faq: {
      in_post_uk_l2a: [
        'kb.accordion.courier.link2',
        'kb.accordion.courier.link3',
        'kb.accordion.courier.link3',
        'kb.accordion.noPrinter.link3',
        // 'kb.accordion.noPrinter.link4',
        'kb.accordion.noPrinter.link5',
        'kb.accordion.noPrinter.link6',
        'kb.accordion.noPrinter.link7',
        'kb.accordion.noPrinter.link8',
      ],
    },
  },
  [Providers.Hermes]: {
    ...defaultValues,
    maxWeight: 15,
    services: {
      standard: {
        analytics: {
          bookID: '221',
          payID: '222',
        },
      },
      no_docs: {
        analytics: {
          bookID: '121',
          payID: '122',
        },
      },
    },
    rules: {
      standard: [
        'rates.rules.[provider].hermes.1',
        'rates.rules.[provider].hermes.2',
        'rates.rules.[provider].hermes.3',
      ],
      no_docs: [
        'rates.rules.[provider].hermes.1',
        'rates.rules.[provider].hermes.2',
        'rates.rules.[provider].hermes.3',
      ],
    },
    faq: {
      standard: [
        'kb:hermes.courier.1',
        'kb:hermes.courier.2',
        'kb:hermes.courier.3',
        'kb:hermes.courier.4',
        'kb:hermes.courier.5',
        'kb:hermes.courier.6',
        'kb:hermes.courier.7',
        'kb:hermes.courier.8',
      ],
      no_docs: [
        'kb:hermes.no_docs.1',
        'kb:hermes.no_docs.2',
        'kb:hermes.no_docs.3',
        // 'kb:hermes.no_docs.4',
        'kb:hermes.no_docs.5',
        'kb:hermes.no_docs.6',
        'kb:hermes.no_docs.7',
        'kb:hermes.no_docs.8',
        'kb:hermes.no_docs.9',
      ],
    },
  },
  [Providers.GLS]: {
    ...defaultValues,
    maxWeight: 30,
    services: {
      gls_return: {
        analytics: {
          bookID: '131',
          payID: '132',
        },
      },
      gls_pick_return: {
        analytics: {
          bookID: '231',
          payID: '232',
        },
      },
    },
    rules: {
      gls_return: ['rates.rules.[provider].gls.1', 'rates.rules.[provider].gls.2', 'rates.rules.[provider].gls.3'],
      gls_pick_return: ['rates.rules.[provider].gls.1', 'rates.rules.[provider].gls.2', 'rates.rules.[provider].gls.3'],
    },
  },
  [Providers.SF]: {
    ...defaultValues,
    maxWeight: 30,
    services: {
      'smart-courier': {
        analytics: {
          bookID: '241',
          payID: '242',
        },
      },
      'smart-express-courier': {
        analytics: {
          bookID: '341',
          payID: '342',
        },
      },
      'smart-standard': {
        analytics: {
          bookID: '141',
          payID: '142',
        },
      },
      'smart-island-ktel': {
        analytics: {
          bookID: '441',
          payID: '442',
        },
      },
    },
    rules: {
      'smart-courier': [
        'rates.rules.[provider].sf.1',
        'rates.rules.[provider].sf.2',
        'rates.rules.[provider].sf.3',
        'rates.rules.[provider].sf.4',
      ],
      'smart-standard': [
        'rates.rules.[provider].sf.1',
        'rates.rules.[provider].sf.2',
        'rates.rules.[provider].sf.3',
        'rates.rules.[provider].sf.4',
      ],
      'smart-express-courier': [
        'rates.rules.[provider].sf.1',
        'rates.rules.[provider].sf.2',
        'rates.rules.[provider].sf.3',
        'rates.rules.[provider].sf.4',
      ],
      'smart-island-ktel': [
        'rates.rules.[provider].sf.1',
        'rates.rules.[provider].sf.2',
        'rates.rules.[provider].sf.3',
        'rates.rules.[provider].sf.4',
      ],
    },
  },
  [Providers.Alfred]: {
    ...defaultValues,
    rules: {
      alfred_department: [
        'rates.rules.[provider].alfred.1',
        'rates.rules.[provider].alfred.2',
        'rates.rules.[provider].alfred.3',
      ],
    },
    faq: {
      alfred_department: [
        'kb:alfred.alfred_department.1',
        'kb:alfred.alfred_department.2',
        'kb:alfred.alfred_department.3',
        'kb:alfred.alfred_department.4',
        'kb:alfred.alfred_department.5',
        'kb:alfred.alfred_department.6',
        'kb:alfred.alfred_department.7',
        'kb:alfred.alfred_department.8',
      ],
    },
  },
  [Providers.UKRPost]: {
    ...defaultValues,
    info: {
      description: 'rates:rates.delivery.partners.ukrposhta',
      logo: '/static/img/partners/up.png',
    },
  },
  [Providers.RusPost]: {
    ...defaultValues,
    info: {
      description: 'rates:rates.delivery.partners.ruspost',
      logo: '/static/img/partners/ruspost.svg',
    },
  },
  [Providers.None]: {
    ...defaultValues,
  },
});

// prepare list of the rules
// which gives discount for self-printed docs
const NO_DOCS_RULES = ['rates.rules.[provider].inpost.4', 'rates.rules.[provider].sf.4'].reduce((acc, rule) => {
  const rules = [Providers.UKRPost, Providers.RusPost].map((provider) => rule.replace('[provider]', provider));

  return [...acc, ...rules];
}, [] as string[]);

export const isDocsRule = (rule: string): boolean => NO_DOCS_RULES.includes(rule);
