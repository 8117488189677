export const isProd = process.env.NODE_ENV === 'production';
export const isDev = !isProd;

const isSandbox = process.env.USE_SANDBOX === '1';

const env = {
  api: {
    dev: 'https://devapi.smartpost.global/api/v1',
    prod: 'https://api.smartpost.global/api/v1',
  },
};

const payPalConfig = Object.freeze({
  sandbox: 'ASbwuAeI0EKyNqq4xfv0mXoCH1GJZ1QKIw8qf9brcZxkIcwRDjWAf6tGQrdhK_rfaf6cky5BYXHwYTlS',
  production: 'AQUiHudu0UwPb1kxiAiZux3tmGAUgjrJsP2dQm-W1YEOjdGRtwzpcM5dPUm7qjVjwTGMkFfalFNIZM-x',
});

const cryptoConfig = Object.freeze({
  sandbox: 'pk_test_8p9mT1akxJBfkZcbBCwAu5PL',
  production: 'pk_live_zXW663x48S7kndNEGzeRa141',
});

export const stripeConfig = Object.freeze({
  sandbox:
    'pk_test_51HcfGZGs5eMw0zBv3BfjXFGHPN07xe0GQUuB6Faxam0eICFS5iCxUNrMzvOX0ioM4W8MYqs6PeNr6AGh5Q0KLP3Y00aOYmHtE7',
  production:
    'pk_live_51HcfGZGs5eMw0zBvsHw3SOasfaLKNV5Rxx4JQir0ryr4MprQRegKJvVvsvPqz8Tjn2lSTXsbalos35GVV8YeA2x700uDE52fNW',
});

export const inPostGBStripeConfig = Object.freeze({
  sandbox:
    'pk_test_51IY6grA0mrQKg6t4gH2jtEDiseYU3PG9vBgCzxsfnemoYJxbDmJOwuNIKYH5Wk0E3kZLYmIcZGkVT1WpIN2sKa6H0051Dcdvtp',
  production:
    'pk_live_51IY6grA0mrQKg6t4rcxaEBuj0N4nV9jFIwRf162CMhWsssRB1YPdEuCkribg1SC0ZyA2dxFM9GJ16HTrhZ9p4BtD00bkCWyWwp',
});

export const isTestEnv = isDev || isSandbox;
export const isLive = isProd && !isTestEnv;
export const isHMR = isDev && !isSandbox;
export const isNewYear = false;

export const config = {
  api: {
    baseURL: isTestEnv ? env.api.dev : env.api.prod,
    auth: {
      username: 'frontuser@smartpost.global',
      password: 'E8bbNRsC',
    },
  },
  payments: {
    isPayPalEnabled: false,
    isPirauesEnabled: false,
  },
  payPal: {
    clientID: isTestEnv ? payPalConfig.sandbox : payPalConfig.production,
  },
  stripe: {
    publishable: isTestEnv ? stripeConfig.sandbox : stripeConfig.production,
  },
  crpyto: {
    publishable: isTestEnv ? cryptoConfig.sandbox : cryptoConfig.production,
  },
};
