export enum Providers {
  InPost = 'inpost',
  InPostGB = 'inpost_gb',
  Hermes = 'hermes',
  GLS = 'gls',
  SF = 'smart_forwarding',
  UKRPost = 'ukrposhta',
  RusPost = 'ruspost',
  Alfred = 'alfred',
  None = 'None',
}

export enum PaymentMethods {
  PayPal = 'paypal',
  Crypto = 'crypto',
  ApplePay = 'apple',
  Piraeus = 'piraeus',
  Stripe = 'stripe',
}

export interface ParcelTemplate {
  label: string;
  type: string;
  width: number;
  height: number;
  length: number;
  maxWeight: number;
}

export interface ProviderInfo {
  description: string;
  logo: string;
}

export interface ProviderConfig {
  maxWeight: number;
  maxWidth: number;
  maxHeight: number;
  maxLength: number;
  parcelTemplates: Record<string, ParcelTemplate[]>;
  rules: Record<string, string[]>;
  faq: Record<string, string[]>;
  services: Record<
    string,
    Partial<{
      customSize?: boolean;
      maxWeight: number;
      analytics?: {
        bookID?: string;
        payID?: string;
      };
    }>
  >;
  extra: {
    dropOffPoint?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  };
  info?: ProviderInfo;
}
