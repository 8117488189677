import { Country } from '../countries';
import { ParcelTemplate, ProviderConfig, Providers } from './types';

import { providersConfig } from './providers.config';

export const isRusPost = (provider: Providers): boolean => provider === Providers.RusPost;
export const isSF = (provider: Providers): boolean => provider === Providers.SF;
export const isAlfred = (provider: Providers): boolean => provider === Providers.Alfred;
export const isGLS = (provider: Providers): boolean => provider === Providers.GLS;
export const isInPostGB = (provider: Providers): boolean => provider === Providers.InPostGB;
export const isHermes = (provider: Providers): boolean => provider === Providers.Hermes;
export const isInPost = (provider: Providers): boolean => provider === Providers.InPost;
export const isInPostLocker = (provider: Providers, service: string): boolean =>
  isInPost(provider) && service === 'postomat';

export const isInPostGBInstant = (provider: Providers, service: string): boolean =>
  isInPostGB(provider) && service === 'in_post_uk_instant';

export const isInPostReturn = (provider: Providers, service: string): boolean =>
  isInPost(provider) && service === 'return';

export const isInPostCourier = (provider: Providers, service: string): boolean =>
  isInPost(provider) && service === 'courier';

export const getProviderConfig = (provider: Providers): ProviderConfig => providersConfig[provider];
export const getCountryProvider = ({ provider }: Country): ProviderConfig => getProviderConfig(provider);

export const getProviderParcelTemplates = (provider: Providers, service: string): ParcelTemplate[] => {
  const { parcelTemplates } = getProviderConfig(provider);

  return Array.isArray(parcelTemplates[service]) ? parcelTemplates[service] : [];
};

export const getProviderService = (provider: Providers, bookService: string): string => {
  if (isInPost(provider)) {
    switch (bookService) {
      case 'postomat': {
        return 'inpost_courier_c2c';
      }

      case 'courier': {
        return 'inpost_courier_standard';
      }

      case 'return': {
        return 'inpost_return';
      }

      default:
        break;
    }
  }

  if (isHermes(provider)) {
    switch (bookService) {
      case 'standard': {
        return 'hermes_standard';
      }

      case 'no_docs': {
        return 'hermes_no_docs';
      }

      default:
        break;
    }
  }

  if (isGLS(provider)) {
    return bookService;
  }

  if (isSF(provider)) {
    return bookService;
  }

  if (isAlfred(provider)) {
    return bookService;
  }

  if (isInPostGB(provider)) {
    return bookService;
  }

  // display debug info for unknown service

  // eslint-disable-next-line no-console
  console.error(`[WARN]: unknown service`);
  // eslint-disable-next-line no-console
  console.log({
    provider,
    bookService,
  });

  return '';
};

export const hasDocs = (provider: Providers, service: string): boolean => {
  if (isInPost(provider)) {
    return ['courier', 'postomat'].includes(service);
  }

  if (isHermes(provider)) {
    return ['standard'].includes(service);
  }

  if (isGLS(provider)) {
    return ['gls_pick_return'].includes(service);
  }

  return false;
};

export const providerToRouteSlug = (provider: Providers): string =>
  // replace underscore to dash symbol
  provider.split('_').join('-');
